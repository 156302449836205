import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      token: null,
      nickname: '',
      point: 0,
    },
  },
  mutations: {
    // 读取本地缓存状态
    reloadLocalStorage(state) {
      if (localStorage.getItem('stateUser')) {
        state.user = JSON.parse(localStorage.getItem('stateUser'));
      } else {
        state.user = {
          token: null,
          nickname: '',
          point: 0,
        };
      }
    },
    // 更新用户状态
    setUser(state, loginData) {
      state.user.token = loginData.api_token;
      state.user.nickname = loginData.name;
      state.user.point = loginData.point;
      localStorage.setItem('stateUser', JSON.stringify(state.user));
    },
  },
  actions: {},
  modules: {},
});
