<template>
  <div id="app">
    <div id="top-right">
      <span style="padding-right: 6px;" @click="checkLogin">
        <span v-if="user.nickname"><router-link to="/user">{{ user.nickname }}</router-link></span>
        <span v-else>{{ $t("default.goto_sign") }}</span>
      </span>

      <select v-model="$i18n.locale" @click="selectLang">
        <option value="cns">简中</option>
        <option value="en">English</option>
      </select>
    </div>
    <div id="nav">
      <router-link to="/">Home</router-link>
      |
      <router-link to="/blog">{{ $t("blog.title") }}</router-link>
      |
      <router-link to="/trans">{{ $t("trans.title") }}</router-link>
      |
      <router-link to="/about">{{ $t("about.title") }}</router-link>
    </div>
    <router-view/>
    <div id="footer">
      <router-link to="/policy/privacy">{{ $t("policy.privacyTitle") }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trans',
  data: () => ({}),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
  },
  methods: {
    // 检查登录
    checkLogin() {
      if (!this.user.token && this.$route.path !== '/sign') {
        localStorage.setItem('lastURI', this.$route.path);
        this.$router.push('sign');
      }
    },
    // 选择语言
    selectLang() {
      if (this.$i18n.locale !== localStorage.getItem('local')) {
        localStorage.setItem('local', this.$i18n.locale);
      }
    },
  },
};
</script>

<style lang="scss">
div {
  margin: 1px 0;
}

button {
  margin: 3px;
  padding: 2px 6px;
}

input {
  height: 20px;
  margin-left: 2px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

a {
  color: cornflowerblue;
  text-decoration: underline;
}
a:hover {
  color: cornflowerblue;
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#footer {
  font-size: 14px;

  a {
    color: #a6bebe;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#top-right {
  float: right;
}
</style>
