import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/policy/privacy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy_policy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog_list" */ '../views/blog/Blog.vue'),
    children: [
      {
        path: 'edit/:id?',
        name: 'Edit',
        component: () => import(/* webpackChunkName: "blog_edit" */ '../views/blog/Edit.vue'),
      },
    ],
  },
  {
    path: '/trans',
    name: 'TransIndex',
    component: () => import(/* webpackChunkName: "trans" */ '../views/trans/Index.vue'),
  },
  {
    path: '/game-scheme/config',
    name: 'GameSchemeByStandardConfig',
    component: () => import(/* webpackChunkName: "game-scheme_standard_config" */ '../views/gameScheme/StandardConfig.vue'),
  },
  {
    path: '/game-scheme/i18',
    name: 'GameSchemeByLocalLanguage',
    component: () => import(/* webpackChunkName: "game-scheme_local_language" */ '../views/gameScheme/LocalLanguage.vue'),
  },
  {
    path: '/game-scheme/city',
    name: 'GameSchemeByCity',
    component: () => import(/* webpackChunkName: "game-scheme_city" */ '../views/gameScheme/City.vue'),
  },
  {
    path: '/game-scheme/space-k',
    name: 'GameSchemeBySpaceK',
    component: () => import(/* webpackChunkName: "game-scheme_space_k" */ '../views/gameScheme/SpaceK.vue'),
  },
  {
    path: '/game-scheme/groundhog-life',
    name: 'GameSchemeByGroundhogLife',
    component: () => import(/* webpackChunkName: "game-scheme_groundhog_life" */ '../views/gameScheme/GroundhogLife.vue'),
  },
  {
    path: '/game-scheme/immortality-clan',
    name: 'GameSchemeByImmortalityClan',
    component: () => import(/* webpackChunkName: "game-scheme_immortality_clan" */ '../views/gameScheme/ImmortalityClan.vue'),
  },
  {
    path: '/game-scheme/tiny-adventure',
    name: 'GameSchemeByTinyAdventure',
    component: () => import(/* webpackChunkName: "game-scheme_tiny_adventure" */ '../views/gameScheme/TinyAdventure.vue'),
  },
  {
    path: '/sign',
    name: 'Sign',
    component: () => import(/* webpackChunkName: "user_sign" */ '../views/user/Sign.vue'),
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user_info" */ '../views/user/User.vue'),
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "user_forget_password" */ '../views/user/ForgetPassword.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
