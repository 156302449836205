import Vue from 'vue';
import axios from 'axios';
import tip from 'sweetalert2';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
store.commit('reloadLocalStorage');
Vue.prototype.$tip = tip;

// Axios
const url = {
  production: 'https://api.uiosun.com/',
  development: 'http://www.blog.com/',
};
Vue.prototype.$http = axios.create({
  baseURL: url[process.env.NODE_ENV],
});

const vueObj = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

// 请求中间件
Vue.prototype.$http.interceptors.request.use(
  (config) => {
    const { token } = store.state.user;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
// 响应拦截器
Vue.prototype.$http.interceptors.response.use((response) => response, (error) => {
  switch (error.response.status) {
    case 401:
      localStorage.removeItem('stateUser');
      tip.fire({
        title: vueObj.$t('default.ops'),
        text: vueObj.$t('default.token_failed_to_sign'),
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes',
      }).then(() => {
        localStorage.setItem('lastURI', window.location.pathname);
        window.location = '/sign';
      });
      return null;
    default:
      break;
  }
  return Promise.reject(error);
});
vueObj.$mount('#app');
